import { SITE_URLS } from "@common/constants"
import { getImagesCdnUrl } from "@common/utils/env"

export const NAVBAR_ASSETS_URL = `${getImagesCdnUrl()}/navbar/`

export const NAV_CTA_IDS = {
	refer_and_earn: "NAV-REFER_AND_EARN",
	assets: "NAV-ASSETS",
	orders: "NAV-ORDERS",
	about_us: "NAV-ABOUT_US",
	wintopedia: "NAV-WINTOPEDIA",
	android_app: "NAV-ANDROID_APP",
	dashboard: "NAV-DASHBOARD",
	funds: "NAV-FUNDS",
	assets_tab: "NAV-ASSETS-TAB",
	logo: "NAV-WINT_LOGO",
	banner_start: "NAV-BANNER_KYC_START",
	your_profile: "MORE-PROFILE-SECTION",
	complete_kyc: "PROFILE-COMPLETE_KYC",
	logout: "PROFILE-LOGOUT",
	your_name: "PROFILE-NAME",
	contact_us: "NAV-CONTACT_US",
	login: "NAV_LOGIN",
	fd: "NAV-FD",
	help: "NAV-HELP",
}

export const DOWNLOAD_APP_BANNER_DATA = {
	title: "Wint Wealth app",
	label: "Available on",
}

export const DOWNLOAD_APP_BANNER_SIDEBAR_DATA = {
	title: "Wint Wealth app",
	label: "4.5",
	rating: "1L+ Downloads",
}

const HOME_ASSETS = `${getImagesCdnUrl()}/homePage`

export const DOWNLOAD_APP_MODAL = {
	title: "Download Wint Wealth mobile app",
	label: "Scan the code to download the app",
	qrImage: `${HOME_ASSETS}/qr-code.svg`,
}

// add pathname bellow in for hidding entire navbar, back button, bottom navbar.

// add routes to hide entire navigation from preferred routes
export const EXCLUDED_ROUTES_FOR_NAV = [
	SITE_URLS.BONDS_REFERRAL_INVITE,
	SITE_URLS.RFQ_BASE_ROUTE,
	SITE_URLS.BONDS_MIGRATED_KYC,
	SITE_URLS.BONDS_CHANGE_BANK,
	SITE_URLS.CAMPAIGN,
	SITE_URLS.PAYMENT_STATUS,
	SITE_URLS.BONDS_KYC,
]

// add routes to hide bottom back navigation from preferred routes
export const EXCLUDED_ROUTES_FOR_TOP_INNERPAGE_BACK_BUTTON = [
	SITE_URLS.NEW_BONDS_DETAILS,
	SITE_URLS.BONDS_HOME,
	SITE_URLS.BONDS_LISTING,
	SITE_URLS.FD_DASHBOARD,
	SITE_URLS.BONDS_DASHBOARD,
	SITE_URLS.SGB_DASHBOARD,
	SITE_URLS.HELP,
	SITE_URLS.PROFILE,
	SITE_URLS.FD_LISTING,
	SITE_URLS.FD_HOME,
]

// add routes to show bottom navbar from preferred routes
export const ALLOWED_ROUTES_BOTTOM_NAVBAR = [
	SITE_URLS.BONDS_HOME,
	SITE_URLS.BONDS_LISTING,
	SITE_URLS.DASHBOARD_V3,
	SITE_URLS.FD_DASHBOARD,
	SITE_URLS.SGB_DASHBOARD,
	SITE_URLS.FD_HOME,
]

// add routes to hide bottom navbar from preferred routes
export const EXCLUDED_ROUTES_FOR_BOTTOM_NAVBAR = [
	SITE_URLS.NEW_BONDS_DETAILS,
	SITE_URLS.BONDS_LISTING,
	SITE_URLS.FD_LISTING,
	SITE_URLS.BONDS_REINVESTMENTS,
]
